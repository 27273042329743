$font-size: 15px;
$font-family : sans-serif;
$purple-color: #2c357c;
$red-color: #ee3842;

@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";

html {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

body {
    background: #fff;
    color: #000;
    font-family: $font-family;
    font-size: $font-size;
    max-width: 600px;
}

.container-content {
    width: 100vw;
    max-width: 600px;
    padding: 135px 3vw 0px 3vw;
    background-color: #fff;
}

.logged-container-content {
    width: 100vw;
    max-width: 600px;
    padding: 135px 3vw 60px 3vw;
    background-color: #fff;
}

a {
    color: $purple-color;
    text-decoration: none;

    &:hover,
    &:active {
        color: $red-color;
    }
}

.btn {

    &-purple {
        background-color: $purple-color;
        color: #fff;

        &:hover,
        &:active {
            background-color: $red-color;
            color: #fff;
        }
    }
}

#welcomeUser {
    font-size: 22px;
}

.text {
    font-weight: bolder;

    &-purple {
        color: $purple-color;
    }

    &-red {
        color: $red-color;
    }
}

.ErrorText,
.input-error {
    color: #f00;
    font-size: 12px;
    font-style: italic;
}

.required-icon {
    margin: 0 2px;
    color: #f00;
}

#resendBtn {
    border: none;
    background-color: transparent;
    color: #2c357c;
}

.d-block {
    display: block;
}

.d-none {
    display: none;
}

.qr-block {
    max-height: calc(100vh - 195px);
}

@import "nav";
@import "throbble";