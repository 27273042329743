$purple-color: rgba(44, 53, 124, .8);
$red-color: rgba(250, 0, 0, .8);

.throbble-wrapper {
    // height: calc(100vh - 80px);
    width: 100vw;
    max-width: 600px;
    top: 50%;
    left: 50%;
    // bottom: 0;
    // right: 0;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    z-index: 999999;
    position: fixed;

    .throbble-inner {
        width: 100%;
        min-height: 120px;
        padding: 0 20px;
        color: #fff;
        background-color: $purple-color;
        border: 2px solid #fff;
        border-radius: 10px;
        text-align: center;

        .spinner-block {
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.4);
            width: -moz-fit-content;
            width: fit-content;
            padding: 2px 2px 0 2px;
            font-size: 14px;
            margin: 0 auto ;
        }

        .message-block {
            font-size: 18px;
        }
    }
}